import React from 'react';
import './MiddleCard.css';
import { Col, Card, Row, Container } from "react-bootstrap";
import { FaCircle, FaShopify } from "react-icons/fa";
import Icon from '@mdi/react';
import { mdiFaceAgent, mdiPencilRuler, mdiDrawPen, mdiCellphoneText, mdiMonitorCellphone } from '@mdi/js';
import { useNavigate,useLocation } from 'react-router-dom';


const MiddleCard = () => {

  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();

  const TechnicalService = () => {
    navigate('/technical_service');
  };

  const GraphicDesignService = () => {
    navigate('/graphic_design_service');
  };

  const UIDesignService = () => {
    navigate('/ui_service');
  };

  const MobileAppService = () => {
    navigate('/mobile_service');
  };

  const WebsiteService = () => {
    navigate('/website_service');
  };

  const EcommerceService = () => {
    navigate('/ecommerce_service');
  };

  return (
    <div className='MiddleCard'>
      <Container fluid>
        <Row>
          <Col md={2} className="middlecard_service_icon">
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
            &nbsp;Services&nbsp;
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="middlecard_service_shorts">
            <span style={{ color: "white" }}>coderz solution</span>
            <br />
            <span style={{ color: "#0957AD" }}>Services Section</span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/technical_service" && "active"}`} onClick={() => TechnicalService()}>
              <Card.Body>
                <div className="first_middle_card">
                  <div className="icon-container">
                    <Icon path={mdiFaceAgent} size={1.5} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>Technical Support</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/graphic_design_service" && "active"}`} onClick={() => GraphicDesignService()}>
              <Card.Body>
                <div className="first_middle_card" >
                  <div className="icon-container">
                    <Icon path={mdiPencilRuler} size={1.5} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>Graphic Design</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/ui_service" && "active"}`} onClick={() => UIDesignService()}>
              <Card.Body>
                <div className="first_middle_card">
                  <div className="icon-container">
                    <Icon path={mdiDrawPen} size={1.5} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>UI/UX Designes</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/mobile_service" && "active"}`} onClick={() => MobileAppService()}>
              <Card.Body>
                <div className="first_middle_card" >
                  <div className="icon-container">
                    <Icon path={mdiCellphoneText} size={1.5} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>Mobile App Development</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/website_service" && "active"}`} onClick={() => WebsiteService()}>
              <Card.Body>
                <div className="first_middle_card">
                  <div className="icon-container">
                    <Icon path={mdiMonitorCellphone} size={1.5} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>Website Development</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={`transparent-card ${currentPath === "/ecommerce_service" && "active"}`} onClick={() => EcommerceService()}>
              <Card.Body>
                <div className="first_middle_card">
                  <div className="icon-container">
                    <FaShopify style={{ fontSize: "2.25rem" }} />
                  </div>
                  <b className="centered" style={{ fontSize: "17px" }}>Ecommerce</b>
                  <span className="centered">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MiddleCard;