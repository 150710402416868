import React from "react";
import './TeamIntro.css';
import { Col, Row, Container } from "react-bootstrap";
import Slider from 'react-slick';
import { FaCircle } from "react-icons/fa";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TeamIntro = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
    };

    return (
        <div className="TeamIntro">

            <img
                src="./images/teamIntro1.png"
                alt="Banner"
                className="teamIntroImage"
                width={"100%"}
            />

            <div className="telantedteam">
                <Row>
                    <Col md={6} className="telantedteams_icon">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Our Team&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="sad">
                        <span style={{ color: "white" }}>CODERZ SOLUTION</span>
                        <br />
                        <span style={{ color: "white" }}>Talented Team</span>
                    </Col>
                </Row>
            </div>

            {/* team intro card */}
            <Container fluid>
                <div className="TeamintroCarad">
                    <Row>
                        <Slider {...settings} style={{ width: "1200px", paddingLeft: "100px" }}>
                            <Col md={4}>
                                <div className="testing" style={{ paddingTop: "200px" }}>
                                    <div className="triangle"></div>
                                    <div className="teamintroimg"><img src="./images/menTeam.png" alt="" width={"160px"} className="triangular-img" /></div>
                                    <div className="teamintroAbout">
                                        <span style={{ fontWeight: "700" }}>Kashif</span>
                                        <br />
                                        <span style={{ color: "black" }}>Lorem Ipsum is simply text of the printing and typesetting</span>
                                    </div>
                                    <div className="triangles1"></div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="testing" style={{ paddingTop: "200px" }}>
                                    <div className="triangle"></div>
                                    <div className="teamintroimg"><img src="./images/menTeam.png" alt="" width={"160px"} className="triangular-img" /></div>
                                    <div className="teamintroAbout">
                                        <span style={{ fontWeight: "700" }}>Kashif</span>
                                        <br />
                                        <span style={{ color: "black" }}>Lorem Ipsum is simply text of the printing and typesetting</span>
                                    </div>
                                    <div className="triangles2"></div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="testing" style={{ paddingTop: "200px" }}>
                                    <div className="triangle"></div>
                                    <div className="teamintroimg"><img src="./images/menTeam.png" alt="" width={"160px"} className="triangular-img" /></div>
                                    <div className="teamintroAbout">
                                        <span style={{ fontWeight: "700" }}>Kashif</span>
                                        <br />
                                        <span style={{ color: "black" }}>Lorem Ipsum is simply text of the printing and typesetting</span>
                                    </div>
                                    <div className="triangles3"></div>
                                </div>
                            </Col>
                        </Slider>
                    </Row>
                </div>
            </Container>
            {/* end team intro card */}

        </div>
    );
}


export default TeamIntro;

