import React from "react";
import './portfolio.css';
import { Col, Row } from "react-bootstrap";
import { FaCircle, FaArrowRight } from "react-icons/fa";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PortfolioDetail = () => {

    //slick slider
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "20px"
                }
            }
        ]
    };

    //redirection
    const navigate = useNavigate();

    //redirect to project detail
    const handleButtonClick = (data) => {
        navigate('/projectview', { state: data });
    };

    const handleAllProject = () => {
        navigate('/portfolio');
    }

    //all array detail
    const projectDetail = {
        0: {
            name: "Hilib Meats App",
            slug: "This is meat Supply app"
        },
        1: {
            name: "Showz Meats Apps",
            slug: "This is meat Supply app"
        },
        2: {
            name: "Plus Meats Apps",
            slug: "This is meat Supply app"
        },
        3:{
            name: "Books Plus Apps",
            slug: "This is meat Supply app"
        }
    };

    //convert into object
    const projectArray = Object.values(projectDetail);

    const cards = projectArray.map((project, index) => (
        <Col key={index} md={6} className='portfolio_card' style={{ width: "46%" }}>
            <Slider {...settings}>
                <div>
                    <img src="./images/portfolio.jpeg" alt="slider1" />
                </div>
                <div>
                    <img src="./images/portfolio.jpeg" alt="slider2" />
                </div>
                <div>
                    <img src="./images/portfolio.jpeg" alt="slider3" />
                </div>
            </Slider>
            <hr />
            <div className="portfolio_card1">
                <span className='portfolio_card1text'>{project.name}</span>
                <div className='portfolio_card1_button'>
                    <button onClick={() => handleButtonClick(project)}>View Full Project <FaArrowRight style={{ fontSize: "15px" }} /></button>
                </div>
                <br />
                <span>{project.slug}</span>
            </div>
            <div className="card_square_box"></div>
            <div className="card_circle_box circle1"></div>
            <div className="card_circle_box circle2"></div>
            <div className="card_circle_box circle3"></div>
        </Col>
    ));

    return (
        <div className="portfolio_detail_main_section">
            <div className="portfolio_detail_main_image">
                <img
                    src="./images/portfoiliDetail.png"
                    alt="Banner"
                />
                <div className="portfolio_detail_main_overlay">
                    <div className="portfolio_detail_main_overlay_text">
                        Portfolio
                    </div>
                </div>
            </div>

            <div className="portfolio_samples">
                <Row>
                    <Col md={12} className="portfolio_samples_icon">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Portfolio&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="portfolio_detail_process_icon">
                        <span style={{ color: "#6D767D" }}>LATEST Work Samples</span>
                        <br />
                        <span style={{ color: "#0957ad", display: "block", marginTop: "-10px" }}>Our Portfolio</span>
                    </Col>
                </Row>
                <div className="main_portfolio_detail_tab_set">
                    <ul>
                        <li><button className="portfolio_detail_tab_set" onClick={() => handleAllProject()}>All Projects</button></li>
                        <li><button className="portfolio_detail_tab_set">Technical Support</button></li>
                        <li><button className="portfolio_detail_tab_set">Graphic Design</button></li>
                        <li><button className="portfolio_detail_tab_set">UI/UX Designs</button></li>
                        <li><button className="portfolio_detail_tab_set">Apps Development</button></li>
                        <li><button className="portfolio_detail_tab_set">Website Development</button></li>
                        <li><button className="portfolio_detail_tab_set">Ecommerce</button></li>
                    </ul>
                </div>

                <Row style={{ paddingTop: "20px" }}>
                    {cards}
                </Row>
            </div>

        </div>
    )
}

export default PortfolioDetail;