import React from "react";
import './ProjectView.css';
import { Col, Row } from "react-bootstrap";
import { FaCircle, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ProjectView = () => {

    const navigate = useNavigate();

    const handleRedirectBack = () => {
        navigate('/portfolio');
    };

    const handleAllProject = () => {
        navigate('/portfolio');
    }

    const location = useLocation();
    const { state: data } = location;

    return (
        <div className="project_detail_main_section">
            <div className="project_detail_main_image">
                <img
                    src="./images/portfoiliDetail.png"
                    alt="Banner"
                />
                <div className="project_detail_main_overlay">
                    <div className="project_detail_main_overlay_text">
                        Full Project View
                    </div>
                </div>
            </div>
            <div className="portfolio_samples">
                <Row>
                    <Col md={12} className="portfolio_samples_icon">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Portfolio&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="portfolio_detail_process_icon">
                        <span style={{ color: "#6D767D" }}>LATEST Work Samples</span>
                        <br />
                        <span style={{ color: "#0957ad", display: "block", marginTop: "-10px" }}>Our Portfolio</span>
                    </Col>
                </Row>
                <div className="main_portfolio_detail_tab_set">
                    <ul>
                        <li><button className="portfolio_detail_tab_set" onClick={() => handleAllProject()}>All Projects</button></li>
                        <li><button className="portfolio_detail_tab_set">Technical Support</button></li>
                        <li><button className="portfolio_detail_tab_set">Graphic Design</button></li>
                        <li><button className="portfolio_detail_tab_set">UI/UX Designs</button></li>
                        <li><button className="portfolio_detail_tab_set">Apps Development</button></li>
                        <li><button className="portfolio_detail_tab_set">Website Development</button></li>
                        <li><button className="portfolio_detail_tab_set">Ecommerce</button></li>
                    </ul>
                </div>
            </div>

            <div className="project_name_heading">
                <div className="square-box">
                    <span className="centered-text">{data?.name} project Full view</span>
                    <button className="bottom-right-button" onClick={() => handleRedirectBack()}><FaArrowLeft />&nbsp;&nbsp;Back</button>
                </div>
            </div>

            <div className="project-full-detail-view">

                <div className="images-container">
                    <img src='../images/projectimage.png' alt="Project" className="project-image" />
                </div>
                <div className="details-container">
                    <img src='../images/steps.jpg' alt="Project" className="right-project-image" />
                    <div className="project-details">
                        <span>PROJECT DETAILS</span>
                        <br />
                        <br />
                        <p><strong>Category:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;App Design & Development</p>
                        <p><strong>App Type:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hilib Meats App</p>
                        <p><strong>Project date:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-10-2023 to 10-11-2023</p>
                        <p><strong>Live Link:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://applink">https://applink</a></p>
                        <p><strong>Description:</strong></p><p className="discription-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    </div>
                    <div className="process-steps">
                        <span>PROCESS</span>
                        <div className="step1">
                            <p><strong>Setting Design Goal:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step2">
                            <p><strong>Conducting User Research:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step3">
                            <p><strong>Creating a Wireframe:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step4">
                            <p><strong>Developing Visual Design:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step5">
                            <p><strong>Prototype:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step6">
                            <p><strong>Handing Over to Developer:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="step7">
                            <p><strong>Gathering User Feedback:</strong></p><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProjectView;