import React from 'react';
import './Portfolio.css';
import { Col, Row, Container } from "react-bootstrap";
import { FaCircle, FaArrowRight } from "react-icons/fa";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20px"
        }
      }
    ]
  };

  const navigate = useNavigate();

    //redirect to project detail
    const redirectToAllProject = () => {
        navigate('/portfolio');
    };
 
  return (
    <div className="main_portfolio">
      <Container fluid>
        <Row>
          <Col md={4} className="portfolio_icons">
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
            &nbsp;Portfolio&nbsp;
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="portfolio_icon">
            <span style={{ color: "#6D767D" }}>LATEST Work Samples</span>
            <br />
            <span style={{ color: "#0957ad" }}>Our Portfolio</span>
          </Col>
        </Row>

        <Row>
          <Col md={6} className='portfolio_card' style={{ width: "48%" }}>
            <Slider {...settings}>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider1" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider2" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider3" />
              </div>
            </Slider>
            <hr />
            <div className="portfolio_card1">
              <span className='portfolio_card1text'>Hilib Meats App</span>
              <div className='portfolio_card1_button'>
                <button>View Full Project <FaArrowRight style={{ fontSize: "15px" }} /></button>
              </div>
              <br />
              <span>This is meat Supply app</span>
            </div>
            <div className="card_square_box"></div>
            <div className="card_circle_box circle1"></div>
            <div className="card_circle_box circle2"></div>
            <div className="card_circle_box circle3"></div>
          </Col>
          <Col md={6} className='portfolio_card' style={{ width: "48%" }}>
            <Slider {...settings}>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider1" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider2" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider3" />
              </div>
            </Slider>
            <hr />
            <div className="portfolio_card1">
              <span className='portfolio_card1text'>Hilib Meats App</span>
              <div className='portfolio_card1_button'>
                <button>View Full Project <FaArrowRight style={{ fontSize: "15px" }} /></button>
              </div>
              <br />
              <span>This is meat Supply app</span>
            </div>
            <div className="card_square_box"></div>
            <div className="card_circle_box circle1"></div>
            <div className="card_circle_box circle2"></div>
            <div className="card_circle_box circle3"></div>
          </Col>
          <Col md={6} className='portfolio_card' style={{ width: "48%" }}>
            <Slider {...settings}>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider1" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider2" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider3" />
              </div>
            </Slider>
            <hr />
            <div className="portfolio_card1">
              <span className='portfolio_card1text'>Hilib Meats App</span>
              <div className='portfolio_card1_button'>
                <button>View Full Project <FaArrowRight style={{ fontSize: "15px" }} /></button>
              </div>
              <br />
              <span>This is meat Supply app</span>
            </div>
            <div className="card_square_box"></div>
            <div className="card_circle_box circle1"></div>
            <div className="card_circle_box circle2"></div>
            <div className="card_circle_box circle3"></div>
          </Col>
          <Col md={6} className='portfolio_card' style={{ width: "48%" }}>
            <Slider {...settings}>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider1" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider2" />
              </div>
              <div>
                <img src="./images/portfolio.jpeg" alt="slider3" />
              </div>
            </Slider>
            <hr />
            <div className="portfolio_card1">
              <span className='portfolio_card1text'>Hilib Meats App</span>
              <div className='portfolio_card1_button'>
                <button>View Full Project <FaArrowRight style={{ fontSize: "15px" }} /></button>
              </div>
              <br />
              <span>This is meat Supply app</span>
            </div>
            <div className="card_square_box"></div>
            <div className="card_circle_box circle1"></div>
            <div className="card_circle_box circle2"></div>
            <div className="card_circle_box circle3"></div>
          </Col>
        </Row>

        <div className="portfolioShowMoreButtonContainer">
          <button className='portfolioShowMoreButton' onClick={() => redirectToAllProject()}>
            Show More <FaArrowRight style={{ fontSize: "15px" }} />
          </button>
        </div>
      </Container>
    </div>
  );
}

export default Portfolio;