import React from "react";
import './talkus.css';
import { useNavigate } from 'react-router-dom';

const TalkUs = () => {
    const navigate = useNavigate();

    //redirect to contact us
    const handleButtonContactUs = () => {
        navigate('/contact_us');
    };
    return (
        <>
            <div
                className="main_talkus_section"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    top: "100px",
                    zIndex:"1",
                  
                }}
            >
                <img
                    src="./images/talkus_img.jpg"
                    alt="Banner"
                    width="80%"
                    className="main_talkus_section_image"
                    style={{
                        maxWidth: "69%",
                        height: "auto",
                        borderRadius:"20px",  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)"
                    }}
                />

                <div className="talk_to_us_heading_section">
                    <span style={{ color: "#6D767D" }}>Let's Talk on</span>&nbsp;<span style={{ color: "#0957ad" }}>Your Project</span>
                </div>
                <button className="talk_to_us_button" onClick={()=>handleButtonContactUs()}>Contact Us</button>

            </div>
        </>
    );
}
export default TalkUs;