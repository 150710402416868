import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import "./footer.css";
import { Link } from 'react-router-dom';
import { SocialIcon } from "react-social-icons";
import { FaLocationPin,FaEnvelope,FaPhone } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <div className="main-footer">
      <hr style={{color: "white", opacity: 1,}} />
        <Container fluid>
          <Row>
            <Col md={4}>
              <img
                src="/images/footer-logo.png"
                width="140px"
                height="auto"
                className="d-inline-block "
                alt="alt"
              />
              <p className="text-white" style={{marginTop:"-15px"}}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
              </p>
            </Col>
            <Col className="text-white" md={2}>
              <span className="footerLinks">pages</span>
              <ul className="list-unstyled footer-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/career">Career</Link>
                </li>
                <li>
                  <Link to="/contact_us">Contact Us</Link>
                </li>
              </ul>
            </Col>
            <Col className="text-white" md={2}>
            <span className="footerLinks">Services</span>
              <ul className="list-unstyled footer-list">
                <li>
                <Link to="/technical_service">Support Services</Link>
                </li>
                <li>
                <Link to="/graphic_design_service">Graphic Designs</Link>
                </li>
                <li>
                <Link to="/ui_service">UI/UX Designs</Link>
                </li>
                <li>
                <Link to="/mobile_service">Mobile App Development</Link>
                </li>
                <li>
                <Link to="/mobile_service">IOS App Development</Link>
                </li>
                <li>
                <Link to="/website_service">Website Development</Link>
                </li>
              </ul>
            </Col>
            <Col className="text-white" md={3}>
            <p className="footerLinks">Contact Info</p>
            <p><FaLocationPin/>&nbsp;<span>Lorem Ipsum is simply printing and industry.</span></p>
            <p><FaEnvelope/>&nbsp;<span>hr@coderzsolution</span></p>
            <p><FaPhone/>&nbsp;<span>+00 000 0000</span></p>
            </Col>
          </Row>
        </Container>
      
      </div>
      <div className="copy_right">
          <span>© 2024 Copyright Coderz Solution. All Rights Reserved Refund Policy | Privacy Policy</span>
          <SocialIcon className="socials-icons"
              url="https://facebook.com"
              network="facebook"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://whatsapp.com"
              network="whatsapp"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://twitter.com"
              network="twitter"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://instagram.com"
              network="instagram"
              style={{ height: 30, width: 30, marginRight: "10px" }}
              bgColor="#d6249f"
            />
            <SocialIcon className="socials-icons"
              url="https://linkedin.com"
              network="linkedin"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
      </div>
    </>
  );
};

export default Footer;
