import React from "react";
import './Blog.css';
import { Col, Row } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import BlogCard from "./BlogCard";

const Blog = () => {
    
    return (
        <div className="blog_main_section">
            <div className="blog_main_image">
                <img
                    src="./images/blog.png"
                    alt="Banner"
                />
                <div className="blog_main_overlay">
                    <div className="blog_main_overlay_text">
                        Blog
                    </div>
                </div>
            </div>
            <div className="blog-jobs-section">
                <Row>
                    <Col md={2} className="blog-icons">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Blogs&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="blog_service_shorts">
                        <span style={{ color: "#6D767D" }}>Our</span>
                        <br />
                        <span style={{ color: "#0957AD" }}>Recent Blog</span>
                    </Col>
                    <Col md={9} className="blog_service_shorts_discription">
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    </Col>
                </Row>
            </div>
            <BlogCard/>
        </div>
    );
}

export default Blog;