import React from "react";
import "./Homepage.css";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import { FaArrowRight, FaPlay } from "react-icons/fa";
import ShortIntro from '../short_intro/ShortIntro';
import MiddleCard from '../middle_card/MiddleCard';
import TeamIntro from '../team_intro/TeamIntro';
import Portfolio from '../portfolio/Portfolio';
import Client from '../clients/Client';
const Homepage = () => {
  return (
    <>
      <div className="mainPage">
        {/* banner image */}
        <img
          src="./images/homepagebanner.jpg"
          alt="Banner"
          className="bannerImage"
        />

        {/* banner text */}
        <Container fluid>
          <div className="overlayText">
            We Provide Outstanding Digital
            <div className="businesHead">
              <p>Business</p>
              <p style={{ marginTop: "-50px" }}>Solutions</p>
            </div>
            <p style={{ marginTop: "-30px", color: "#9DBCDA" }}>
              Tomorrow's Solution Today
            </p>
            <div className="bannerDescription">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </p>
              <p style={{ marginTop: "-3%" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </p>
            </div>
            <div className="buttonContainer">
              <button className="bannerContactBtn">
                Contact Us <FaArrowRight style={{ fontSize: "15px" }} />
              </button>
              <div className="playButtonOuterBorder">
                <div className="playButtonMiddleBorder">
                  <div className="playButtonInnerBorder">
                    <FaPlay className="playButtonIcon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* end banner text */}
      </div>
      {/* <Homepage /> */}
      
      {/* components */}
      <ShortIntro />
      <MiddleCard />
      <TeamIntro />
      <Portfolio />
      <Client />
      {/* end components */}
    </>
  );
};

export default Homepage;
