import React from "react";
import "./ShortIntro.css";
import "bootstrap/dist/css/bootstrap.css";
import { FaCircle, FaHandHoldingUsd, FaWrench, FaUsers } from "react-icons/fa";
import { Col, Card, Row, Container } from "react-bootstrap";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ShortIntro = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="shortIntro">
      <Container fluid>

        {/* shortinto */}
        <Row>
          <Col md={2} className="about_us_icon">
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
            &nbsp;About Us&nbsp;
            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
          </Col>
        </Row>
        <Row style={{ marginLeft: "-15px" }}>
          <Col md={3} className="shortIntros">
            <span style={{ color: "#0957AD" }}>Introduction To</span>
            <br />
            <span style={{ color: "#6D767D" }}>CODERZ SOLUTION</span>
          </Col>
          <Col md={5} className="shortpart1">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </Col>
          <Col md={4} className="shortpart2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </Col>
        </Row>
        {/* end short intro */}

        {/* card section */}
        <Row style={{ marginLeft: "-15px", marginTop: "50px" }}>
          <Col md={3} className="firstCardCol">
            <Card style={{ boxShadow: " 0 0 15px 0px rgba(0, 0, 0, 0.5)" }}>
              <Card.Body>
                <div className="firstCard">
                  <div className="icon-container1">
                    <FaHandHoldingUsd />
                  </div>
                  <div className="text-container">
                    <span style={{ color: "#0957ad" }}>
                      <b>Best Price Guaranteed</b>
                    </span>
                    <br />
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="secondCardCol">
            <Card style={{ boxShadow: " 0 0 15px 0px rgba(0, 0, 0, 0.5)" }}>
              <Card.Body>
                <div className="secondCard">
                  <div className="icon-container2">
                    <FaWrench />
                  </div>
                  <div className="text-container">
                    <span style={{ color: "#0957ad" }}>
                      <b>Best Price Guaranteed</b>
                    </span>
                    <br />
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="thirdCardCol">
            <Card style={{ boxShadow: "0 0 15px 0px rgba(0, 0, 0, 0.5)" }}>
              <Card.Body>
                <div className="thirdCard">
                  <div className="icon-container3">
                    <FaUsers />
                  </div>
                  <div className="text-container">
                    <span style={{ color: "#0957ad" }}>
                      <b>Best Price Guaranteed</b>
                    </span>
                    <br />
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* end card section */}

        <Row>
          <Col md={12}>
            <div class="slidersection">
              <div class="left-image">
                <img src="/images/slider2.png" width="500px" height="auto" class="slidersad" alt="alt" />
              </div>
              <div class="slider-container">
                <Slider {...settings} style={{ width: "850px", padding: "140px", paddingLeft: "70px", paddingRight: "70px" }}>
                  <div>
                    <div class="image-container">
                      <img src="/images/slider.jpg" width="100%" height="400" class="d-inline-block" alt="slider1" />
                    </div>
                  </div>
                  <div>
                    <div class="image-container">
                      <img src="/images/slider.jpg" width="100%" height="400" class="d-inline-block" alt="slider1" />
                    </div>
                  </div>
                  <div>
                    <div class="image-container">
                      <img src="/images/slider.jpg" width="100%" height="400" class="d-inline-block" alt="slider1" />
                    </div>
                  </div>
                </Slider>
              </div>
              <div class="right-image">
                <img src="/images/slider1.png" width="500px" height="auto" alt="alt" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ShortIntro;
