import React from "react";
import './about.css';
import ShortIntro from '../short_intro/ShortIntro';
import TeamIntro from '../team_intro/TeamIntro';
import { Col, Card, Row } from "react-bootstrap";
import { FaCircle,FaEye } from "react-icons/fa";
import Icon from '@mdi/react';
import { mdiBullseyeArrow } from '@mdi/js';

const About = () => {
    return (
        <>
            <div className="about_main_section">
                <div className="about_main_image">
                    <img
                        src="./images/about_us_main_image.png"
                        alt="Banner"
                    />
                    <div className="about_main_overlay">
                        <div className="about_main_overlay_text">
                            About Us
                        </div>
                    </div>
                </div>
                <ShortIntro />
                <div className="about_mission_vision_section">
                    <img
                        src="./images/missionvision.jpg"
                        alt="Banner"

                    />
                    <Row>
                        <Col md={2} className="about_mission_vision_icon">
                            <FaCircle style={{ color: "#6D767D", fontSize: "12px" }} />
                            &nbsp;Mission&nbsp;/&nbsp;Vision&nbsp;
                            <FaCircle style={{ color: "#6D767D", fontSize: "12px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="about_mission_vision_titles">
                            <span style={{ color: "white" }}>CODERZ SOLUTION</span>
                            <br />
                            <span style={{ color: "#0957AD", display: "block", marginTop: "-10px" }}>Mission & Vision</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Card className="mission_vision_first_card">
                                <Card.Body>
                                   <div className="eye_div">
                                    <FaEye/>
                                   </div>
                                   <div className="mision_vision_discripion">
                                    <span>Our <b style={{color:"#0957AD"}}>Mission</b></span>
                                    <br/>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                         Lorem Ipsum has been the industry's standard dummy text ever since the typesetting industry. 
                                         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</span>
                                   </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="mission_vision_second_card">
                                <Card.Body>
                                <div className="eye_div">
                                <Icon path={mdiBullseyeArrow} size={1.5} />
                                   </div>
                                   <div className="mision_vision_discripion">
                                    <span>Our <b style={{color:"#0957AD"}}>Vision</b></span>
                                    <br/>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                         Lorem Ipsum has been the industry's standard dummy text ever since the typesetting industry. 
                                         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</span>
                                   </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <TeamIntro />
            </div>

        </>


    );
}

export default About;