import React from "react";
import './MobileAppService.css';
import { FaCircle } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import MiddleCard from '../middle_card/MiddleCard';
import CountUp from 'react-countup';


const MobileAppService = () => {
    
    return (
        <div className="mobileapp-service-main-section">
            <div className="mobileapp-service-banner">
                Mobile App Development
            </div>
            <div className="techincal-service-row">
                <Row>
                    <Col md={6} className="technical-service-column-text">
                        <Row>
                            <Col md={2} className="technical_service_icon">
                                <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                                &nbsp;Our Service&nbsp;
                                <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                            </Col>
                            <span style={{ color: "#0957ad" }}>Mobile App</span>
                            <br />
                            <span style={{ color: "#6D767D",marginTop:"-10px" }}>Development Service</span>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </Row>
                    </Col>
                    <Col md={6} className="technical-service-column-image">
                        <img src="../images/mobile_app_service.png" alt="graphic_design" />
                    </Col>
                </Row>
            </div>
            <div className="clientsCounters">
                <img
                    src="./images/clientscounter.png"
                    alt="Banner"
                    className="clientIntrosSecondSection"
                />
                <div className="total_projects">
                    <p> <CountUp start={0} end={100} duration={10} suffix="+" /></p>
                    <hr />
                    <span>Total Projects</span>
                </div>
                <div className="happy_clients">
                    <p><CountUp start={0} end={75} duration={10} suffix="+" /></p>
                    <hr />
                    <span>Happy Clients</span>
                </div>
                <div className="great_reviews">
                    <p><CountUp start={0} end={90} duration={10} suffix="+" /></p>
                    <hr />
                    <span>Great Reviews</span>
                </div>
            </div>
            <MiddleCard />
        </div>
    );
}

export default MobileAppService;