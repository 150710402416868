import React from "react";
import './career.css';
import { Col, Card, Row } from "react-bootstrap";
import { FaCircle, FaShopify } from "react-icons/fa";
import { mdiFaceAgent, mdiPencilRuler, mdiDrawPen, mdiCellphoneText, mdiMonitorCellphone } from '@mdi/js';
import Icon from '@mdi/react';

const Career = () => {
    return (
        <div className="career_main_section">
            <div className="career_main_image">
                <img
                    src="./images/career.png"
                    alt="Banner"
                />
                <div className="career_main_overlay">
                    <div className="career_main_overlay_text">
                        Career
                    </div>
                </div>
            </div>
            <div className="recomanded-jobs-section">
                <Row>
                    <Col md={2} className="recomanded-icons">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Vacancy/Career&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="recomanded_service_shorts">
                        <span style={{ color: "#6D767D" }}>Recommended</span>
                        <br />
                        <span style={{ color: "#0957AD" }}>Jobs</span>
                    </Col>
                    <Col md={9} className="recomanded_service_shorts_discription">
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    </Col>
                </Row>
            </div>
            <div className="job-categories-section">
                <div className="job-categories">
                    Jobs Categories
                </div>
                <div className="techincal-support-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <Icon path={mdiFaceAgent} size={1.3} style={{ color: "#0957AD" }} />
                        </div>
                        <span className="job-title">Techical Support</span>
                    </div>
                </div>
                <div className="graphic-design-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <Icon path={mdiPencilRuler} size={1.3} style={{ color: "#0957AD" }} />
                        </div>
                        <span className="job-title">Graphic Design</span>
                    </div>
                </div>
                <div className="ui-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <Icon path={mdiDrawPen} size={1.3} style={{ color: "#0957AD" }} />
                        </div>
                        <span className="job-title">UI/UX Designs</span>
                    </div>
                </div>
                <div className="mobile-app-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <Icon path={mdiCellphoneText} size={1.3} style={{ color: "#0957AD" }} />
                        </div>
                        <span className="job-title">Mobile App Development</span>
                    </div>
                </div>
                <div className="website-app-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <Icon path={mdiMonitorCellphone} size={1.3} style={{ color: "#0957AD" }} />
                        </div>
                        <span className="job-title">Website Development</span>
                    </div>
                </div>
                <div className="ecommerce-categories">
                    <div className="career-job-item">
                        <div className="career-job-icons">
                            <FaShopify style={{ fontSize: "2rem", color: "#0957AD" }} />
                        </div>
                        <span className="job-title">Ecommerce</span>
                    </div>
                </div>
                <div className="career-job-card">
                    <div className="speaker-img">
                        <img src="../images/speaker.png" alt="" />
                    </div>
                    <div className="carrer-type">
                        <b>Type:</b>&nbsp;&nbsp;<span>Onsite</span>
                    </div>
                    <div className="carrer-time">
                        <b>Time:</b>&nbsp;&nbsp;<span>09am&nbsp;-&nbsp;06pm</span>
                    </div>
                    <div className="carrer-experience">
                        <b>Min Experience:</b>&nbsp;&nbsp;<span>2&nbsp;year</span>
                    </div>
                    <div className="carrer-hiring-open">
                        <span>Hiring Open</span>
                    </div>
                    <div className="career-requirements">
                        <span>Requirement:</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            It has survived not only five centuries,</p>
                    </div>
                    <div className="career-section-apply">
                        <div className="job-title-section">
                            Mobile App Developer
                        </div>
                        <div className="career-salary-section">
                            Salary&nbsp;70k&nbsp;to&nbsp;80k
                        </div>
                        <div className="career-location"><b>Location:</b>&nbsp;Multan</div>
                        <button className="carer-apply-now-button">Apply Now</button>
                    </div>
                </div>
                <div className="career-job-card-2">
                    <div className="speaker-img">
                        <img src="../images/speaker.png" alt="" />
                    </div>
                    <div className="carrer-type">
                        <b>Type:</b>&nbsp;&nbsp;<span>Onsite</span>
                    </div>
                    <div className="carrer-time">
                        <b>Time:</b>&nbsp;&nbsp;<span>09am&nbsp;-&nbsp;06pm</span>
                    </div>
                    <div className="carrer-experience">
                        <b>Min Experience:</b>&nbsp;&nbsp;<span>2&nbsp;year</span>
                    </div>
                    <div className="carrer-hiring-open">
                        <span>Hiring Open</span>
                    </div>
                    <div className="career-requirements">
                        <span>Requirement:</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            It has survived not only five centuries,</p>
                    </div>
                    <div className="career-section-apply">
                        <div className="job-title-section">
                            Mobile App Developer
                        </div>
                        <div className="career-salary-section">
                            Salary&nbsp;70k&nbsp;to&nbsp;80k
                        </div>
                        <div className="career-location"><b>Location:</b>&nbsp;Multan</div>
                        <button className="carer-apply-now-button">Apply Now</button>
                    </div>
                </div>
            </div>
            <div className="career-benefits-section">
                <Row>
                    <Col md={2} className="about_us_icon">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;About Us&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <div className="join-and-benefits-section">
                    <Row>
                        <Col md={3} className="joinus-careers">
                            <span style={{ color: "#0957AD" }}>Join Us and</span>
                            <p style={{ color: "#6D767D" }}>Reap the Benefits</p>
                        </Col>
                        <Col md={5} className="joinus-careers-first">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s.
                        </Col>
                        <Col md={4} className="joinus-careers-second">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s.
                        </Col>
                    </Row>
                </div>
                <div className="benefits-carrer-cards">
                    <Row>
                        <Col md={3} className="firstCardCol">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/heart-beat.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />

                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Medical Benefits</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5} className="firstCardCols">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/work-money.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />
                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Paid Leaves</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="firstCardColss">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/certificate.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />
                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Certifications</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                    <br />
                    <Row>
                        <Col md={3} className="firstCardCol">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/money.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />
                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Annual Bonuses</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5} className="firstCardCols">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/pump.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />

                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Fuel Allowance</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="firstCardColss">
                            <Card style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)" }}>
                                <Card.Body>
                                    <div className="firstCard">
                                        <div className="icon-container1">
                                            <img
                                                src="./images/surpise.png"
                                                alt="Banner"
                                                width={"30px"}
                                            />
                                        </div>
                                        <div className="text-container">
                                            <span style={{ color: "#0957ad" }}>
                                                <b>Celebrations & Outings</b>
                                            </span>
                                            <br />
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className="applynow-form">
                    <img src="./images/applynowform.png" alt="" className="apply-now-form-image" />
                    <form action="" >
                        <input type="text" className="input-fields" placeholder="Name" />
                        <div className="career-emails">
                            <input type="email" className="input-fields" placeholder="Email" />
                        </div>
                        <div className="career-phones">
                            <input type="number" className="input-fields" placeholder="Phone" />
                        </div>
                        <div className="career-positions">
                            <select className="input-fields">
                                <option value="" disabled selected hidden>Position</option>
                                <option value="Backend">Backend</option>
                                <option value="Frontend">Frontend</option>
                                <option value="Fullstack">Fullstack</option>
                                <option value="DevOps">DevOps</option>
                            </select>
                        </div>
                        <div className="career-attachments">
                            <input type="file" id="file-input" hidden />
                            <label for="file-input" className="input-fields">
                                <span className="attachment-icon">&#128206;</span>
                                <span className="placeholder-text">Attach Resume</span>
                            </label>
                        </div>
                        <div className="career-portfolio">
                            <input type="text" className="input-fields" placeholder="Portfolio Link" />
                        </div>
                        <div className="career-message">
                            <textarea name="message" id="message" className="input-fields" cols="50" rows="4" placeholder="Enter your message"></textarea>
                        </div>
                        <div className="career-apply-form-button">
                            <button className="carer-apply-now-button">Apply Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Career;