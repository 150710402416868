import React from "react";
import './client.css';
import { Col, Row } from "react-bootstrap";
import { FaCircle, FaQuoteLeft,FaStar,FaQuoteRight } from "react-icons/fa";
import CountUp from 'react-countup';

const Client = () => {
    return (
        <div className="parent_client_section">
            <div className="client_section">

                <Row>
                    <Col md={4} className="client_section_icons">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Clients&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>


                <Row>
                    <Col md={3} className="clientIntros" style={{ marginLeft: "-16px" }}>
                        <span style={{ color: "#6D767D" }}>Whom We Served</span>
                        <br />
                        <span style={{ color: "#0957AD" }}>Featured Clients</span>
                    </Col>
                    <Col md={3} style={{ marginLeft: "10px" }}>
                        <img
                            src="./images/clients1.png"
                            alt="Banner"
                            className="clientIntrosImage"
                            width={"80%"}
                        />
                    </Col>
                    <Col md={3}>
                        <img
                            src="./images/clients2.png"
                            alt="Banner"
                            className="clientIntrosImage"
                            width={"80%"}
                        />
                    </Col>
                    <Col md={3}>
                        <img
                            src="./images/clients3.png"
                            alt="Banner"
                            className="clientIntrosImage"
                            width={"80%"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} style={{ marginLeft: "-16px", textAlign: "justify" }}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                    </Col>
                    <Col md={3}>
                        <img
                            src="./images/client4.png"
                            alt="Banner"
                            className="clientIntrosSecondSection"
                            width={"80%"}
                        />
                    </Col>
                    <Col md={3}>
                        <img
                            src="./images/client5.png"
                            alt="Banner"
                            className="clientIntrosSecondSection"
                            width={"80%"}
                        />
                    </Col>
                    <Col md={3}>
                        <img
                            src="./images/client6.png"
                            alt="Banner"
                            className="clientIntrosSecondSection"
                            width={"80%"}
                        />
                    </Col>
                </Row>

                
            </div>
            <div className="clientsCounters">
                    <img
                        src="./images/clientscounter.png"
                        alt="Banner"
                        className="clientIntrosSecondSection"
                    />
                    <div className="total_projects">
                        <p> <CountUp start={0} end={100} duration={10} suffix="+"/></p>
                        <hr />
                        <span>Total Projects</span>
                    </div>
                    <div className="happy_clients">
                        <p><CountUp start={0} end={75} duration={10} suffix="+"/></p>
                        <hr />
                        <span>Happy Clients</span>
                    </div>
                    <div className="great_reviews">
                        <p><CountUp start={0} end={90} duration={10} suffix="+"/></p>
                        <hr />
                        <span>Great Reviews</span>
                    </div>
                </div>
            <div className="testimonail">
                <img
                    src="./images/testimonail.png"
                    alt="Banner"
                    width={"100%"}
                />
                <div className="testimonal_icons_section">
                    <Row>
                        <Col md={4} className="testimonal_icons_section_child">
                            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                            &nbsp;Testimonails&nbsp;
                            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="testimonal_icons_section_child_second">
                            <span style={{ color: "#6D767D" }}>What People Say</span>
                            <br />
                            <span style={{ color: "#0957ad" }}>About Us</span>
                        </Col>
                    </Row>

                </div>

                <div className="testimonal_square_box">
                    <div className="circle">
                        <img
                            src="./images/menTeam.png"
                            alt="Banner"
                            className="circle-image"
                        />

                    </div>
                    <div className="testimonial_client_name">
                        <span style={{ color: "#0957ad" }}>Merlin</span>
                        <br />
                        <span style={{ color: "#6D767D" }}>Ea-Stamp User</span>
                    </div>
                    <span className="circule_columns"><FaQuoteLeft /></span>

                </div>
                <div className="client_testimonial_reviews">
                    <div className="client_testimonial_reviews_first_child">
                        <span style={{ color: "#0957ad",fontWeight:"700" }}>Excelletent App</span>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                    <div className="clients_review_star">
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                    <FaQuoteRight className="final_review_quotes"/>
                </div>
            </div>
        </div>
    );
}

export default Client;