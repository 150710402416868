import React, { useState, useEffect } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const BlogCard = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [blogs, setBlogs] = useState([]);
    
    useEffect(() => {
        fetch(`${apiUrl}api/blog`)
          .then(response => {
            console.log(response);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setBlogs(data.data);
        })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, [apiUrl]);

    
    const navigate = useNavigate();
    const handleReadMore = (card) => {
        navigate('/blog_post', { state: card });
    };

    const handleAll = () => {

        navigate('/blog');
    };

    const truncateDescription = (description) => {
        const words = description.split(' ');
        if (words.length > 10) {
            return words.slice(0, 25).join(' ') + '...';
        }
        return description;
    }

    const truncateTitle = (title) => {
        return title.length > 10 ? title.substring(0, 10) + '...' : title;
    };
    
    let cards;
    if (blogs.length === 0) {
        cards = <p style={{textAlign:"center",fontSize:"30px"}}>No Blogs Are Posted Yet</p>; 
    } else {
        cards = blogs.map((blog, index) => (
            <Col key={index} md={4}>
                <Card className="first_blog_card first-cards-bloger">
                    <Card.Body>
                        <div className="first_blog_card">
                            <img src={blog.image} alt="blog" style={{ width: "90%" }} />
                        </div>
                        <div className="blog-container-card">
                            <span style={{ color: "#0957ad" }}>
                                <b>{truncateTitle(blog.title)}</b>
                            </span>
                            <br />
                            <p>
                                {truncateDescription(blog.description)}
                            </p>
                            <p style={{ color: "#0957ad" }}>
                                {new Date(blog.created_at).toDateString()}
                            </p>
                            <button className="blog-read-more-button" onClick={() => handleReadMore(blog)}>Read More</button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        ));
    }

    return (
        <div className="blog-card-section">
            <Row>
                {cards}
            </Row>
            <div className="button-container">
                <button className="blog-show-more-button" onClick={() => handleAll()}>Show More&nbsp;<FaArrowRight /></button>
            </div>
        </div>
    );
}

export default BlogCard;