import React from "react";
import './contact.css';
import { Col, Row } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";

const ContactUs = () => {
    return (
        <div className="contact_us_main_section">
            <div className="contact_us_main_image">
                <img
                    src="./images/contact_us.png"
                    alt="Banner"
                />
                <div className="contact_us_main_overlay">
                    <div className="contact_us_main_overlay_text">
                        Contact Us
                    </div>
                </div>
            </div>
            <div className="contact-us-jobs-section">
                <Row>
                    <Col md={2} className="contact-us-icons">
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        &nbsp;Contact Us&nbsp;
                        <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="contact_us_service_shorts">
                        <span style={{ color: "#6D767D" }}>Get In Touch</span>
                        <br />
                        <span style={{ color: "#0957AD" }}>Contact Us</span>
                    </Col>
                    <Col md={9} className="contact_us_service_shorts_discription">
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    </Col>
                </Row>
            </div>
            <div className="contact_us_form_image">
                <img src="./images/contact_us_form.png" alt="contact us" />
                <div className="contact_us_image_detail">
                    <p><img src="./images/phone.png" alt="phone"/>&nbsp;&nbsp;+92 000 000 0000</p>
                    <br />
                    <p><img src="./images/directinbox.png" alt="info"/>&nbsp;&nbsp;info@coderzsolution.com</p>
                    <br />
                    <p><img src="./images/location.png" alt="location"/>  Loreum Ipseum is simply dummy</p>
                </div>
            </div>
            <div className="contact_us_form_fields">
            <form action="#" >
                        <input type="text" className="input-fields" placeholder="Name" />
                        <div className="career-emails">
                            <input type="email" className="input-fields" placeholder="Email" />
                        </div>
                        <div className="career-phones">
                        <select className="input-fields">
                                <option value="" disabled selected hidden>Services</option>
                                <option value="Backend">Backend</option>
                                <option value="Frontend">Frontend</option>
                                <option value="Fullstack">Fullstack</option>
                                <option value="DevOps">DevOps</option>
                            </select>
                        </div>
                        <div className="career-positions">
                            <select className="input-fields">
                                <option value="" disabled selected hidden>Request Meeting Online/ Physical</option>
                                <option value="Backend">Backend</option>
                                <option value="Frontend">Frontend</option>
                                <option value="Fullstack">Fullstack</option>
                                <option value="DevOps">DevOps</option>
                            </select>
                        </div>
                        <div className="contact-us-message">
                            <textarea name="message" id="message" className="input-fields" cols="50" rows="7" placeholder="Enter your message"></textarea>
                        </div>
                        <div className="contact-us-apply-form-button">
                            <button className="carer-apply-now-button">Submit</button>
                        </div>
                    </form>
            </div>
        </div>
    )
}

export default ContactUs;