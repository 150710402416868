import React from "react";
import { FaClock, FaSearch } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SocialIcon } from "react-social-icons";
import "bootstrap/dist/css/bootstrap.css";
import "./Nav.css";
import Navbars from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const servicePaths = [
    '/service',
    '/ecommerce_service',
    '/website_service',
    '/technical_service',
    '/mobile_service',
    '/ui_service',
    '/graphic_design_service'
  ];

  return (
    <>
      <Container fluid style={{ margin: "0px" }}>
        <Row className="top-bar">
          <Col style={{ fontSize: "15vm" }}>Welcome to our Coderz Solutions Company</Col>
          <Col className="text-center">
            <span className="fontawsome-clock">
              <FaClock />
            </span>
            Office Hours: 9:00 AM - 8:00 PM
          </Col>
          <Col className="social-icon text-end">
            <SocialIcon className="socials-icons"
              url="https://facebook.com"
              network="facebook"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://whatsapp.com"
              network="whatsapp"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://twitter.com"
              network="twitter"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
            <SocialIcon className="socials-icons"
              url="https://instagram.com"
              network="instagram"
              style={{ height: 30, width: 30, marginRight: "10px" }}
              bgColor="#d6249f"
            />
            <SocialIcon className="socials-icons"
              url="https://linkedin.com"
              network="linkedin"
              style={{ height: 30, width: 30, marginRight: "10px" }}
            />
          </Col>
        </Row>
      </Container>
      <Navbar expand="lg" className="bg-body-tertiary ">
        <Container fluid className="navbar-container" style={{ height: "70px" }}>
          <Navbar.Brand href="#home">
            <img
              src="/images/logo.jpeg"
              width="140px"
              height="auto"
              className="d-inline-block align-top"
              alt="alt"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Navbars className="ms-auto" style={{ marginRight: 'auto' }} >
              <Navbars.Link href="/" active={currentPath === '/'}>Home</Navbars.Link>
              <Navbars.Link href="/about" active={currentPath === '/about'}>About</Navbars.Link>
              <Navbars.Link href="/service" active={servicePaths.includes(currentPath)}>Services</Navbars.Link>
              <Navbars.Link href="/portfolio" active={currentPath === '/portfolio' || currentPath === '/projectview'}>Portfolio</Navbars.Link>
              <Navbars.Link href="/career" active={currentPath === '/career'}>Career</Navbars.Link>
              <Navbars.Link href="/blog" active={currentPath === '/blog' || currentPath === '/blog_post'}>Blog</Navbars.Link>
              <Navbars.Link href="/contact_us" active={currentPath === '/contact_us'}>Contact Us</Navbars.Link>
            </Navbars>
            <Form className="d-flex">
              <Button variant="link">
                <FaSearch style={{ fontSize: "25px" }} />
              </Button>
              <Button className="contactButton" style={{ width: "100%", padding: "10px 40px 10px 40px", backgroundColor: "#0957a2" }}>
                Contact Us
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Nav;
