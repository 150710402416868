import './App.css';
import Footer from './component/footer/Footer';
import Nav from './component/navbar/Nav';
import Homepage from './component/homepage/Homepage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Service from './component/service/Service';
import TalkUs from './component/talkus/TalkUs';
import About from './component/about/About';
import PortfolioDetail from './component/portfolio_page/ProtfolloDetail';
import Career from './component/career/Career';
import ContactUs from './component/contact_us/ContactUs';
import ProjectView from './component/project_view/ProjectView';
import TechnicalService from './component/technical_service/TechnicalService';
import MobileAppService from './component/mobile_app_service/MobileAppService';
import Ecommerce from './component/ecommerce_service/EcommerceService';
import GraphicDesign from './component/graphic_design_service/GraphicService';
import UiService from './component/ui_service/UiService';
import WebsoteService from './component/website_service/WebsiteService';
import Blog from './component/blog/Blog';
import BlogPost from './component/blog_post/BlogPost';


function App() {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

function Content() {
  return <MainRoutes />;
}

function MainRoutes() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/portfolio" element={<PortfolioDetail />} />
        <Route path="/projectview" element={<ProjectView />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/technical_service" element={<TechnicalService />} />
        <Route path="/mobile_service" element={<MobileAppService />} />
        <Route path="/ecommerce_service" element={<Ecommerce />} />
        <Route path="/graphic_design_service" element={<GraphicDesign />} />
        <Route path="/ui_service" element={<UiService />} />
        <Route path="/website_service" element={<WebsoteService />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog_post" element={<BlogPost />} />
      </Routes>
      <TalkUs />
      <Footer />
    </>
  );
}

export default App;
