import React from "react";
import './service.css';
import Middlecard from '../middle_card/MiddleCard'
import { Col, Row } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";

const Service = () => {
    return (
        <>
            <div className="service_main_section">
                <div className="service_main_image">
                    <img
                        src="./images/service.png"
                        alt="Banner"
                    />
                    <div className="service_main_overlay">
                        <div className="service_main_overlay_text">
                            Services
                        </div>
                    </div>
                </div>
                <Middlecard />
                <div className="service_graphimage">
                    <img
                        src="./images/services_12.png"
                        alt="Banner"
                    />
                    <Row>
                        <Col md={12} className="service_service_icon">
                            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                            &nbsp;Our Process&nbsp;
                            <FaCircle style={{ color: "#6D767D", fontSize: "15px" }} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={6} className="service_process_icon">
                            <span style={{ color: "#6D767D" }}>Process</span>
                            <br />
                            <span style={{ color: "#0957ad",display:"block",marginTop:"-10px" }}>We Follow</span>
                        </Col>
                        <Col md={6} className="service_process_description">
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                             Lorem Ipsum has been the industry's standard dummy.</span>
                        </Col>
                    </Row>

                    <div className="first_circle_description">
                        <span style={{color:"#0957ad"}}>Research Project</span>
                        <br/>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                    </div>
                    <div className="second_circle_description">
                        <span style={{color:"#0957ad"}}>Evaluate Plans</span>
                        <br/>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                    </div>
                    <div className="third_circle_description">
                        <span style={{color:"#0957ad",marginLeft:"7px"}}>Best Results</span>
                        <br/>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Service;